import React, { useState, useContext } from 'react'
import { Box, Flex, Text, Image, Heading, Spinner, Link } from 'theme-ui'
import PageHero from '../components/Heros/PageHero'
import { store } from '../context/store'
import ContentBlockCenter from '../components/ContentBlocks/ContentBlockCenter'
import Cells from '../components/PrivateEvents/Cells'
import SideBar from '../components/PrivateEvents/SideBar'
import TitleBlock from '../components/ContentBlocks/TitleBlock'
import DiningRoomBlocks from '../components/PrivateEvents/DiningRoomBlocks'
import PrivateDiningForm from '../components/Forms/PrivateDiningForm'

export default function PrivateEvents(props) {
  const { globalState, dispatch } = useContext(store)

  const { businessData, stories: allGeneral } = globalState

  const privateEventsStories = [
    'a-art-rnyx1k7nR7qghodj7-5yLg',
    'a-art-Ks0g_Ge7Qf65cNkEYOhNMA',
    'a-art-FdHUJchfSY_8n_kQ8w1V6A',
    'a-art-HUsQbfjySYWOS_fEzRwfQQ',
  ]

  // finds all the stories in the private Stories Array
  const filteredStories = allGeneral.filter(
    (story) => privateEventsStories.indexOf(story.node.id) !== -1
  )

  const scrollToElement = (id) => {
    const element = document.getElementById(id)
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }

  const renderCells = () =>
    filteredStories.map((story, index) => {
      const { title, id, media } = story.node
      return (
        <Box
          sx={styles.menuCell}
          onClick={() => {
            scrollToElement(id)
          }}
        >
          <Image
            sx={styles.menuCellImage}
            src={`https://res.cloudinary.com/gonation/${media?.[0].cloudinaryId}`}
          />
          <Heading sx={styles.menuCellTitle}>{title}</Heading>
        </Box>
      )
    })

  return (
    <Box sx={styles.container}>
      <PageHero imageID={'private-events-pagehero'} />

      <ContentBlockCenter
        storyId={'a-art-Oz9ZWwT8TyWKJHCRbio2dA'}
        sx={{ padding: '2rem 3rem' }}
      />

      <PrivateDiningForm />

      <Cells stories={filteredStories} scrollToElement={scrollToElement} />
      {/* side label */}
      <SideBar stories={filteredStories} scrollToElement={scrollToElement} />

      <TitleBlock
        storyId={'a-art-SP8f6tKBSSWiGdfCLg8mmA'}
        sx={styles.titleBlock}
      />

      <DiningRoomBlocks stories={filteredStories} />
    </Box>
  )
}

const styles = {
  // ?============================
  // ?======  Containers =========
  // ?============================
  container: {
    paddingTop: '62px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  titleBlock: {
    padding: '2rem 2rem 8rem 6rem',
    width: '100%',
    '.title': {
      fontSize: ['2rem', '2.5rem', '3rem'],
      textAlign: 'left',
    },
  },
}
