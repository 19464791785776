import React from 'react'
import { Box, Text, Heading, Image } from 'theme-ui'

export default function Cells({ stories, scrollToElement }) {
  const renderCells = () =>
    stories?.map((story, index) => {
      const { title, id, media } = story.node
      return (
        <Box
          sx={styles.menuCell}
          onClick={() => {
            scrollToElement(id)
          }}
        >
          <Image
            sx={styles.menuCellImage}
            src={`https://res.cloudinary.com/gonation/${media?.[0].cloudinaryId}`}
          />
          <Heading sx={styles.menuCellTitle}>{title}</Heading>
        </Box>
      )
    })

  return <Box sx={styles.menuCellsContainer}>{renderCells()}</Box>
}

const styles = {
  // ?============================
  // ?======  Containers =========
  // ?============================

  menuCellsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'flex-start',
    marginBottom: '3rem',
    padding: ['', '', '0rem 3rem'],
  },
  menuCell: {
    width: ['calc(50% - 1rem)', '', 'calc(33.3% - 1rem)', ''],
    position: 'relative',
    margin: '0.5rem',
    overflow: 'hidden',
    cursor: 'pointer',
    ':hover': {
      img: {
        transform: 'scale(1.2)',
      },
      h2: {
        color: 'light',
      },
    },
  },

  menuCellImage: {
    transition: 'all ease-in-out 0.8s',
    height: ['250px', '300px'],
    objectFit: 'cover',
    width: '100%',
  },
  menuCellTitle: {
    transition: 'all ease-in-out 0.8s',
    position: 'absolute',
    bottom: '1rem',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    width: '90%',
    textAlign: 'center',
    backgroundColor: 'primary',
    color: 'light',
    height: ['30%', '', '20%'],
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    textTransform: 'capitalize',
    borderColor: 'light',
    fontWeight: '400',
    fontSize: ['1rem', '1.2rem'],
    justifyContent: 'center',
    transition: 'all ease-in-out 0.5s',
  },
}
