import React from 'react'
import { Box, Text, Heading, Flex, Spinner, Link, Image } from 'theme-ui'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import shortid from 'shortid'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import LazyLoad from 'react-lazyload'
import TextContent from '../Text/TextContent'

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <FaChevronRight {...props} />
)

const PrevArrow = ({ currentSlide, slideCount, ...props }) => (
  <FaChevronLeft {...props} />
)

const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  autoplay: true,
  speed: 1500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
}

export default function DiningRoomBlocks({ stories }) {
  return (
    <Box sx={styles.sectionsContainer}>
      {stories.map((story, index) => {
        const {
          title,
          subtitle,
          bodyHTML,
          ctaName,
          ctaLink,
          description,
          id,
          media,
        } = story.node

        const buttons =
          ctaName && ctaLink ? [{ name: ctaName, link: ctaLink }] : []

        return (
          <Flex sx={styles.sectionContainer} id={id}>
            <Flex className='content' sx={styles.content}>
              <TextContent
                title={title}
                subtitle={subtitle}
                text={bodyHTML}
                buttons={buttons}
              />
            </Flex>

            {media?.length > 0 ? (
              <Box className='imageContainer' sx={styles.imageContainer}>
                <Slider {...settings}>
                  {media.map((image) => (
                    <>
                      <Box
                        className='sliderImageFill'
                        sx={styles.sliderImageFill}
                      />
                      <Image
                        sx={styles.sliderImage}
                        src={`https://res.cloudinary.com/gonation/${image.cloudinaryId}`}
                      />
                    </>
                  ))}
                </Slider>
              </Box>
            ) : (
              ''
            )}
          </Flex>
        )
      })}
    </Box>
  )
}

const styles = {
  sectionsContainer: {
    width: '100%',
    '> div:nth-of-type(even)': {
      '.content': {
        order: ['', '', '2'],
      },
    },
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: ['column', '', 'row'],
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'red',
    width: '100%',
  },

  // ?============================
  // ?======  Image Content =======
  // ?============================

  //   introImage: {
  //     width: '100%',
  //     objectFit: 'cover',
  //     height: '60vh',
  //   },

  imageContainer: {
    width: ['100%', '', '49%'],
    // padding: '1rem',
    overflow: 'hidden',
    position: 'relative',
    maxHeight: '100vh',
    // TODO: Give Slider A Wrapper Variant and then put slick styles in there instead of in container
    '.slick-slider': {
      width: '100%',
    },
    '.slick-initialized .slick-slide > div': {
      overflow: 'hidden',
      position: 'relative',
      height: '100%',
    },
    '.slick-arrow': {
      width: '30px',
      zIndex: '100',
      height: '30px',
      backgroundColor: 'white',
      path: {
        fill: 'primary',
      },
    },
    '.slick-prev': {
      left: '0rem',
    },
    '.slick-next': {
      right: '0rem',
    },
  },

  sliderImageFill: { paddingBottom: '100%' },
  sliderImage: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    objectFit: 'cover',
    transform: 'translate(-50%, -50%)',
    objectFit: 'cover',
  },

  // ?============================
  // ?======  Text Content =======
  // ?============================

  introTextContainer: {
    padding: '3rem 1rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: '1000px',
    margin: '0rem auto',
  },

  content: {
    display: 'flex',
    padding: ['2rem 1rem', '', '2rem', '3rem 5rem', '3rem 7rem'],
    flexDirection: ['column', '', 'column'],
    width: ['100%', '', '49%'],
    justifyContent: ['', '', 'center'],
  },

  title: {
    marginBottom: 3,
    fontSize: 8,
    fontWeight: '300',
  },
  subtitle: {
    marginBottom: 3,
    fontSize: 4,
    fontWeight: 'normal',
  },
  body: {},

  // ?============================
  // ?========  Buttons  =========
  // ?============================

  ctaButton: {
    display: 'flex',
    marginTop: '1rem',
    textAlign: 'center',
    padding: '1rem 3rem',
    backgroundColor: 'secondary',
    width: 'fit-content',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    transition: 'all ease-in-out 0.5s',
    // ?== Psuedo Elements ==
    '&:hover': {
      backgroundColor: 'primary',
    },
  },
}
