import React, { useState } from 'react'
import { Box, Text, Heading, Flex, Spinner } from 'theme-ui'

export default function SideBar({ stories, scrollToElement }) {
  const [sideBarActive, setSideBarActive] = useState(false)

  const renderSideBarList = () =>
    stories.map(({ node: { title, id } }) => (
      <Box
        key={`sidebar${id}`}
        sx={styles.menuCellSideBar}
        onClick={() => {
          scrollToElement(id)
        }}
      >
        <Heading sx={styles.menuCellSideBarTitle}>{title}</Heading>
      </Box>
    ))

  return (
    <Box
      sx={{
        ...styles.menuCellsSideBar,
        transition: 'all ease-in-out 0.8s',
        left: `${sideBarActive ? '0px' : '-300px'}`,
      }}
      onMouseEnter={() => {
        setSideBarActive(true)
      }}
      onMouseLeave={() => {
        setSideBarActive(false)
      }}
    >
      <Box sx={styles.sideBarList}>{renderSideBarList()}</Box>
      <Text sx={styles.menuCellSideBarLabel}>Our Private Rooms</Text>
    </Box>
  )
}

const styles = {
  //  Side Bar

  menuCellsSideBar: {
    position: 'fixed',
    left: '-300px',
    top: ['70px', '30%'],
    backgroundColor: 'primary',
    color: 'white',
    width: '300px',
    padding: '1rem',
    zIndex: '1000',
  },

  sideBarList: {
    minHeight: '200px',
  },

  menuCellsSideBarActive: {
    // variant: `${variantName}.menuCellsSideBar`,
    left: '0rem',
  },

  menuCellSideBar: {},

  menuCellSideBarTitle: {
    fontSize: ['0.8rem', '', '1rem'],
    fontWeight: '300',
    margin: '0.5rem',
    borderBottom: 'solid 1px',
    padding: '0.5rem',
    color: 'light',
    cursor: 'pointer',
    textTransform: 'capitalize',
    ':hover': {
      color: 'grey',
    },
  },

  menuCellSideBarLabel: {
    position: 'absolute',
    writingMode: 'tb',
    right: '0rem',
    top: '0rem',
    padding: ['1rem 0.2rem', '', '2rem 0.5rem'],
    transform: 'translate(100%, 0px)',
    backgroundColor: 'primary',
    minHeight: 'fit-content',
    fontSize: ['0.7rem', '0.8rem', '1rem'],
  },
}
