import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../FormComponents/FormikControl';
import netlifyFormEncodeBody from '../../helpers/netlifyFormEncodeBody';
import { Heading, Button } from '@theme-ui/components';

function ContactForm(props) {
  const { sx, style } = props;

  const sourceOptions = [
    { key: 'Google', value: 'google' },
    { key: 'Yelp', value: 'yelp' },
    { key: 'Trip Advisor', value: 'trip-advisor' },
    { key: 'Media', value: 'media' },
    { key: 'Recommendation', value: 'recommendation' },
    { key: 'Other', value: 'other' },
  ];

  const checkboxOptions = [{ key: 'Flexible', value: 'true' }];

  const initialValues = {
    name: '',
    phone: '',
    email: '',
    message: '',
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email format').required('Required'),
    phone: Yup.string()
      .required('Required')
      .matches(phoneRegExp, 'Phone number is not valid'),
    message: Yup.string().required('Required'),
  });

  const onSubmit = (values, { resetForm }) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: netlifyFormEncodeBody({
        'form-name': 'private-dining-form',
        ...values,
      }),
    })
      .then(() => {
        alert('Email Sent!');
        resetForm({});
      })
      .catch((error) => alert(error));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Form sx={{ ...styles.container, ...style, ...sx }}>
            <Heading as='h3' sx={styles.heading}>
              Inquiry Form
            </Heading>

            <input type='hidden' name='form-name' value='private-dining-form' />
            <FormikControl
              control='input'
              type='name'
              label='Name'
              name='name'
              placeholder='Enter Your Name'
            />
            <FormikControl
              control='input'
              type='email'
              label='Email'
              name='email'
              placeholder='Enter Your Email Address'
            />

            <FormikControl
              control='input'
              type='phone'
              label='Phone'
              name='phone'
              placeholder='Enter Your Phone Number'
            />

            <FormikControl
              control='input'
              type='text'
              label='Event Type'
              name='eventtype'
              placeholder='The Type Of Event'
            />

            <FormikControl
              control='date'
              label='Event Date'
              name='eventdate'
              placeholder='The Date Of The Event'
            />

            <FormikControl
              control='checkbox'
              label='Flexible On Date?'
              name='flexibledate'
              options={checkboxOptions}
            />

            <FormikControl
              control='input'
              label='Event Time'
              type='time'
              name='eventtime'
              placeholder='What Time Will The Event Start'
            />

            <FormikControl
              control='input'
              type='number'
              label='Number Of Guests'
              name='numberguests'
              placeholder='Enter The Number Of Guests Attending(Approx)'
            />

            <FormikControl
              control='input'
              label='Company'
              name='companyname'
              placeholder='Company Name (Optional)'
            />

            <FormikControl
              placeholder='Enter Your Message Here'
              control='textarea'
              label='Message'
              name='message'
              rows='5'
            />

            <FormikControl
              control='radio'
              label='How Did You Hear About Us?'
              name='source'
              options={sourceOptions}
            />
            <Button
              type='submit'
              sx={styles.submitBtn}
              disabled={!formik.isValid}
            >
              Submit
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}

const styles = {
  container: {
    width: '100%',
    maxWidth: '500px',
    padding: '4rem 2rem',
    border: 'solid 1px lightgrey',
    margin: '2rem 0rem',
    '.form-control': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginBottom: '1rem',
    },
    '.radio-control': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'wrap',
      '.radioButtonContainer': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '0rem 1rem 0.5rem 0rem',
      },
      input: {
        width: 'auto',
        margin: '0rem 0.5rem 0rem 0rem',
      },
    },

    '.checkbox-control': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'wrap',
      '.radioButtonContainer': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '0rem 1rem 0.5rem 0rem',
      },
      input: {
        width: 'auto',
        margin: '0rem 0.5rem 0rem 0rem',
      },
    },

    '.input-control': {
      input: {
        border: 'none',
        borderBottom: 'solid 1px lightgrey',
        width: '100%',
        padding: '0.75rem',
        '::placeholder': {
          fontSize: '0.7rem',
          opacity: '0.7',
        },
      },
    },
    '.date-control': {
      input: {
        border: 'none',
        borderBottom: 'solid 1px lightgrey',
        width: '100%',
        padding: '0.75rem',
        '::placeholder': {
          fontSize: '0.7rem',
          opacity: '0.7',
        },
      },
    },
    '.form-label': {
      width: '100%',
    },

    '.error': {
      color: 'red',
      fontSize: '0.75rem',
      textAlign: 'right',
      width: '100%',
    },

    textarea: {
      border: 'none',
      borderBottom: 'solid 1px lightgrey',
      margin: '0.5rem 0rem',
      width: '100%',
      padding: '0.75rem',

      resize: 'none',
      '::placeholder': {
        fontSize: '0.7rem',
        opacity: '0.7',
      },
    },
  },

  heading: {
    color: 'primary',
    textDecoration: 'underline',
    textAlign: 'center',
    width: '100%',
    textTransform: 'capitalize',
    marginBottom: '1.5rem',
    fontSize: ['1.5rem', '1.75rem', '2rem'],
  },

  submitBtn: {
    variant: 'buttons.primary',
  },
};

export default ContactForm;

// import React, { useContext } from 'react';
// import { Textarea, Input, Button, Box, Heading } from 'theme-ui';

// export default function ContactForm(props) {
//   const { sx, style } = props;
//   const formName = 'private-dining-form';

//   const onSubmit = (values) => {
//     console.log('Form data', values);

//     fetch('/', {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//       body: netlifyFormEncodeBody({
//         'form-name': formName,
//         ...values,
//       }),
//     })
//       .then(() => {
//         alert('Email Sent');
//         resetForm({});
//       })
//       .catch((error) => alert(error));
//   };

//   return (
//     <Box
//       sx={{ ...styles.container, ...style, ...sx }}
//       as='form'
//       name={formName}
//       method='POST'
//       netlify={'true'}
//       data-netlify='true'
//       data-netlify-honeypot='bot-field'
//       onSubmit={onSubmit}
//     >
//       <Input type='hidden' name='form-name' value={formName} />
//       <Input type='hidden' name='bot-field' />
//       <Heading as='h3' sx={styles.heading}>
//         Inquiry Form
//       </Heading>

//       <Input
//         sx={styles.input}
//         name='firstname'
//         type='text'
//         id='firstname'
//         placeholder='First Name'
//         required
//       />
//       <Input
//         sx={styles.input}
//         name='lastname'
//         type='text'
//         id='lastname'
//         placeholder='Last Name'
//         required
//       />
//       <Input
//         sx={styles.input}
//         type='phone'
//         name='phone'
//         id='phone'
//         placeholder='Phone'
//         className='inputField email'
//       />
//       <Input
//         sx={styles.input}
//         type='email'
//         name='email'
//         id='email'
//         placeholder='Email'
//         className='inputField email'
//         required
//       />
//       <Input
//         sx={styles.input}
//         name='typeofevent'
//         type='text'
//         id='typeofevent'
//         placeholder='Type Of Event'
//         required
//       />
//       <Input
//         sx={styles.date}
//         name='date'
//         type='date'
//         id='date'
//         placeholder='Date Of Event' // placeholder in css as place holder doesn't work with dates
//       />
//       <Box sx={styles.checkboxContainer}>
//         <label for='flexibledate'>My date is flexible</label>
//         <Input
//           sx={styles.checkbox}
//           type='checkbox'
//           id='flexibledate'
//           name='flexibledate'
//           value='Flexible On Date'
//         />
//       </Box>
//       <Input
//         sx={styles.input}
//         name='numberguests'
//         type='number'
//         id='numberguests'
//         placeholder='Number Of Guests' // placeholder in css as place holder doesn't work with dates
//         required
//       />
//       <Input
//         sx={styles.input}
//         name='eventtime'
//         type='time'
//         id='eventtime'
//         placeholder='Event Time' // placeholder in css as place holder doesn't work with dates
//       />
//       <Input
//         sx={styles.input}
//         name='companyname'
//         type='text'
//         id='companyname'
//         placeholder='Company Name (Optional)'
//       />
//       <Textarea
//         sx={styles.textArea}
//         name='message'
//         id='message'
//         rows='4'
//         placeholder='Tell us About your Event/Additional Detail/Comments'
//       />
//       <Button className='button submitBtn' type='submit'>
//         Submit
//       </Button>
//     </Box>
//   );
// }

// const stylesold = {
//   container: {
//     width: '100%',
//     maxWidth: '500px',
//     padding: '4rem 2rem',
//     border: 'solid 1px lightgrey',
//     margin: '2rem 0rem',
//   },
//   heading: {
//     color: 'primary',
//     textDecoration: 'underline',
//     textAlign: 'center',
//     width: '100%',
//     textTransform: 'capitalize',
//     marginBottom: '1.5rem',
//     fontSize: ['1.5rem', '1.75rem', '2rem'],
//   },

//   input: {
//     border: 'none',
//     borderBottom: 'solid 1px lightgrey',
//     margin: '0.5rem 0rem',
//     borderRadius: '0px',
//     '::placeholder': {
//       fontSize: '0.8rem',
//       fontFamily: 'body',
//       letterSpacing: '0px',
//       color: 'grey',
//       fontWeight: '400',
//     },
//   },

//   date: {
//     border: 'none',
//     borderBottom: 'solid 1px lightgrey',

//     '::before': {
//       margin: '0rem 0rem',
//       content: "'Date of Event'",
//       width: '100%',
//       fontSize: '0.8rem',
//       letterSpacing: '0px',
//       fontFamily: 'body',
//       color: 'grey',
//       fontWeight: '400',
//     },

//     ':hover::before': {
//       width: '0%',
//       content: "''",
//     },
//   },
//   checkboxContainer: {
//     display: 'flex',
//     justifyContent: 'flex-start',
//     alignItems: 'center',
//     margin: '1rem 0rem',
//     padding: '0rem 0.5rem',
//     width: 'fit-content',
//     label: {
//       margin: '0rem 0rem',
//       content: "'Date of Event'",
//       width: '100%',
//       fontSize: '0.8rem',
//       letterSpacing: '0px',
//       fontFamily: 'body',
//       color: 'grey',
//       fontWeight: '400',
//     },
//   },
//   checkbox: {
//     width: '15px',
//     height: '15px',
//     appearance: 'auto',
//     // border: 'unset',
//     padding: '0.5rem',
//     marginLeft: '1rem',
//   },
//   textArea: {
//     border: 'none',
//     borderBottom: 'solid 1px lightgrey',
//     margin: '0.5rem 0rem',
//     resize: 'none',
//     '::placeholder': {
//       fontSize: '0.8rem',
//       fontFamily: 'body',
//       letterSpacing: '0px',
//       color: 'grey',
//       fontWeight: '400',
//     },
//     marginBottom: '1.5rem',
//   },
// };
